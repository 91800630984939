import { API_BASE_URL, API_TOKEN } from '../config/apiConfig';
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import React, { useState, useRef, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import "owl.carousel/dist//assets/owl.carousel.css";
import "owl.carousel/dist//assets/owl.theme.default.css";
import "../css/products.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/free-mode";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Virtual, Navigation, Pagination, FreeMode } from "swiper/modules";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import axios from 'axios';
import Select, { components } from 'react-select';

function Products ()
{
  const [ modeOn, setActiveMode ] = useState( "slider" );

  const handleTabClickTab1 = () =>
  {
    const newUrl = new URL( window.location.href );
    newUrl.pathname = '/products/service-parts';
    window.history.replaceState( null, null, newUrl.toString() );
    setActiveMode( "slider" );
  };

  const handleTabClickTab2 = () =>
  {
    const newUrl = new URL( window.location.href );
    newUrl.pathname = '/products/tmo';
    window.history.replaceState( null, null, newUrl.toString() );
    setActiveMode( "slider" );
  };

  const handleTabClickTab3 = () =>
  {
    const newUrl = new URL( window.location.href );
    newUrl.pathname = '/products/tgb';
    window.history.replaceState( null, null, newUrl.toString() );
    setActiveMode( "slider" );
  };

  const handleTabClickTab4 = () =>
  {
    const newUrl = new URL( window.location.href );
    newUrl.pathname = '/products/tire-solution';
    window.history.replaceState( null, null, newUrl.toString() );
    setActiveMode( "slider" );
  };

  const handleTabClickTab5 = () =>
  {
    const newUrl = new URL( window.location.href );
    newUrl.pathname = '/products/t-opt';
    window.history.replaceState( null, null, newUrl.toString() );
    setActiveMode( "banner" );
  };

  const currentLocation = useLocation();
  const tabParam = currentLocation.pathname.split( '/' ).pop();

  const [ activeTab, setActiveTab ] = useState( 'faq_tab_1' );

  useEffect( () =>
  {
    let activeTab = 'faq_tab_1';
    let modeOn = 'banner';
    switch ( tabParam )
    {
      case 'service-parts':
        activeTab = 'faq_tab_1';
        modeOn = 'slider';
        break;
      case 'tmo':
        activeTab = 'faq_tab_2';
        modeOn = 'slider';
        break;
      case 'tgb':
        activeTab = 'faq_tab_3';
        modeOn = 'slider';
        break;
      case 'tire-solution':
        activeTab = 'faq_tab_4';
        modeOn = 'slider';
        break;
      case 't-opt':
        activeTab = 'faq_tab_5';
        modeOn = 'banner';
        break;
      default:
        activeTab = 'faq_tab_1';
        modeOn = 'slider';
        break;
    }
    setActiveTab( activeTab );
    setActiveMode( modeOn );
  }, [ tabParam ] );

  const [ isVisible, setIsVisible ] = useState( false );

  const toggleVisibility = () =>
  {
    setIsVisible( !isVisible );
  };

  const [ isVisible1, setIsVisible1 ] = useState( false );

  const toggleVisibility1 = () =>
  {
    setIsVisible1( !isVisible1 );
  };

  const [ isVisible2, setIsVisible2 ] = useState( false );

  const toggleVisibility2 = () =>
  {
    setIsVisible2( !isVisible2 );
  };

  const [ isVisible3, setIsVisible3 ] = useState( false );

  const toggleVisibility3 = () =>
  {
    setIsVisible3( !isVisible3 );
  };

  const [ isVisible4, setIsVisible4 ] = useState( false );

  const toggleVisibility4 = () =>
  {
    setIsVisible4( !isVisible4 );
  };

  const [ isVisible5, setIsVisible5 ] = useState( false );

  const toggleVisibility5 = () =>
  {
    setIsVisible5( !isVisible5 );
    setIsVisible6( false ); // Hide component 6
  };

  const [ isVisible6, setIsVisible6 ] = useState( false );

  const toggleVisibility6 = () =>
  {
    setIsVisible6( !isVisible6 );
    setIsVisible5( false ); // Hide component 6
  };

  const value = useSelector( ( state ) => state.value );

  const scrollLeft = () =>
  {
    const myTab = document.getElementById( "myTab" );
    myTab.scrollTo( {
      left: myTab.scrollLeft - 100,
      behavior: "smooth",
    } );
  };

  const goToWeb = () =>
  {
    let url = "https://otoxpert.co.id/workshop";
    window.open( url, '_blank' );
  };

  const scrollRight = () =>
  {
    const myTab = document.getElementById( "myTab" );
    myTab.scrollTo( {
      left: myTab.scrollLeft + 100,
      behavior: "smooth",
    } );
  };

  const [ swiperRef, setSwiperRef ] = useState( null );

  const [ slideContent, setSlides ] = useState( [
    {
      title: "TMO Evaporator Cleaner",
      text: "1",
      description:
        "TMO Evaporator Cleaner membersihkan kotoran ringan pada Evaporator AC kendaraan untuk menjaga sistem AC tetap bekerja optimal & menyegarkan udara keluar dari AC di dalam kabin. Pembersihan dilakukan sesuai dengan standar operasional Toyota.",
      imageUrl: "/assets/Frame_874.png",
      src: "https://www.youtube.com/embed/6iy1Apj8O44"
    },
    {
      title: "TMO Injector Cleaner",
      text: "2",
      description:
        "Cairan yang diformulasi khusus yang dapat melindungi dan membersihkan ruang pembakaran, injector, katup pemasukan-pembuangan serta efektif mengembalikan proforma mesin serta menghemat konsumsi bahan bakar Toyota.",
      imageUrl: "/assets/InjectorCleaner.png",
    },
    {
      title: "TMO Brake Cleaner",
      text: "3",
      description:
        "Aerosol untuk membersihkan komponen pengereman dari debu, grease, oli, pada saat periodic maintenance.",
      imageUrl: "/assets/Frame 874_1.png",
      src: "https://www.youtube.com/embed/myz1qo-MiIk"
    },
    {
      title: "TMO Engine Room Treatment",
      text: "4",
      description:
        "Produk berbahan dasar air yang didesain khusus dengan formula terbaru yang lebih optimal untuk membersihkan & mengkilapkan lebih efektif pada kompartemen ruang mesin mobil. Pembersihan dilakukan sesuai dengan standar operasional Toyota.",
      imageUrl: "/assets/04-Engine Room Treatment.png",
    },
    {
      title: "TMO Glass Cleaner",
      text: "5",
      description:
        "Cairan untuk membersihkan kaca mobil dari kotoran dan jamur (waterspot). Membantu meningkatkan visibilitas saat berkendara.",
      imageUrl: "/assets/GlassCleaner.png",
      src: "https://www.youtube.com/embed/DxPRwtUElR0"
    },
    {
      title: "TMO Wiper Fluid",
      text: "6",
      description:
        "Cairan pembersih kaca mobil & pencegah jamur",
      imageUrl: "/assets/06-Wiper Fluid.png",
    },
    {
      title: "TMO Cabin Disinfectant",
      text: "7",
      description:
        "Aerosol penghilang bakteri* hingga 99,99%* (teruji) dan penyegar kabin.",
      imageUrl: "/assets/07-Cabin Disinfectant.png",
    },
  ] );

  const [ slideContents, setSlidess ] = useState( [
    {
      title: "TMO Differential Gear Oil",
      text: "1",
      description: " Pelumas oil gardan (differential) yang ditujukan untuk penggunaan kendaraan berpenggerak roda belakang (RWD).",
      imageUrl: "/assets/Image12.png",
      src: "https://www.youtube.com/embed/Yten5Nf0KW8"
    },
    {
      title: "TMO Transfer Gear Oil",
      text: "2",
      description: "Pelumas yang digunakan pada kendaraan 4WD sebagai pelindung chain dan bearing. Tanpa adanya oli transfer, gigi akan sulit berputar dan mobil pun terasa berat saat dijalankan.",
      imageUrl: "/assets/Image13.png",
    },
    {
      title: "TMO Manual Transmission Fluid",
      text: "3",
      description: "Pelumas yang dirancang khusus untuk memberikan pelumasan, mengurangi gesekan, dan mendinginkan komponen dalam transmisi manual.",
      imageUrl: "/assets/Image14.png",
    },
    {
      title: "TMO Automatic Transmission Fluid",
      text: "4",
      description: "Pelumas yang dirancang khusus untuk digunakan dalam pelumasan, pendinginan, dan transmisi daya yang efisien dalam transmisi otomatis.",
      imageUrl: "/assets/Image203.png",
    },
  ] );

  // TODO: const prducts, add tab product Sept 24 - Abie
  const [ selectedOption, setSelectedOption ] = useState( "carBrand" );
  const [ selectedTab, setSelectedTab ] = useState( "1" ); // Menggunakan string untuk keselarasan dengan Antd
  const [ loading, setLoading ] = useState( false );
  const [ products, setProducts ] = useState( [] );

  const brandOptions = [
    { value: 'Toyota', label: 'Toyota' },
  ];

  const yearOptions = Array.from( { length: 2025 - 1990 + 1 }, ( v, i ) =>
  {
    const year = 1990 + i;
    return { value: year.toString(), label: year.toString() };
  } );

  const modelOptions = [
    { value: 'Gasoline', label: 'Gasoline' },
    { value: 'Diesel', label: 'Diesel' },
  ];

  const [ selectedBrand, setSelectedBrand ] = useState( null );
  const [ selectedYear, setSelectedYear ] = useState( null );
  const [ carOptions, setCarOptions ] = useState( [] );
  const [ selectedModel, setSelectedModel ] = useState( null );
  const [ selectedCar, setSelectedCar ] = useState( null );
  const [ productOptions, setProductOptions ] = useState( null );
  const [ selectedProduct, setSelectedProduct ] = useState( null );
  const [ genuinePartNumber, setGenuinePartNumber ] = useState( '' ); // Menggunakan state untuk input part number
  const [ isSubmitted, setIsSubmitted ] = useState( false );
  const [ brandError, setBrandError ] = useState( false );
  const [ carError, setCarError ] = useState( false );
  const [ modelError, setModelError ] = useState( false );
  const [ yearError, setYearError ] = useState( false );
  const [ productError, setProductError ] = useState( false );

  const fetchCarOptions = async () =>
  {
    try
    {
      const config = {
        headers: {
          Authorization: `Bearer ${ API_TOKEN }`,
        },
      };

      const response = await axios.get( `${ API_BASE_URL }/car`, config );
      console.log( 'API Response Data:', response.data );

      if ( response.data && Array.isArray( response.data.data ) )
      {
        const carData = response.data.data.map( ( car ) => ( {
          value: car.name,
          label: car.name,
        } ) );
        console.log( 'Mapped Car Data:', carData );
        setCarOptions( carData );
      } else
      {
        console.error( 'Unexpected data format:', response.data );
      }

      setLoading( false );
    } catch ( error )
    {
      console.error( 'Error fetching car options:', error );
      setLoading( false );
    }
  };

  const fetchPoductOptions = async () =>
  {
    try
    {
      const config = {
        headers: {
          Authorization: `Bearer ${ API_TOKEN }`,
        },
      };

      const response = await axios.get( `${ API_BASE_URL }/get_product`, config );
      console.log( 'API Response Data:', response.data );

      if ( response.data && Array.isArray( response.data.data ) )
      {
        const productData = response.data.data.map( ( product ) => ( {
          value: product.id,
          label: product.name,
        } ) );
        console.log( 'Mapped Product Data:', productData );
        setProductOptions( productData );
      } else
      {
        console.error( 'Unexpected data format:', response.data );
      }

      setLoading( false );
    } catch ( error )
    {
      console.error( 'Error fetching product options:', error );
      setLoading( false );
    }
  };

  useEffect( () =>
  {
    fetchCarOptions();
    fetchPoductOptions();
  }, [] );

  const handleBrandChange = ( selectedOption ) =>
  {
    setSelectedBrand( selectedOption );
    console.log( 'Selected Brand:', selectedOption );
  };

  const handleCarChange = ( selectedOption ) =>
  {
    setSelectedCar( selectedOption ); // react-select mengirimkan objek {value, label}
    setSelectedCar( selectedOption ); // react-select mengirimkan objek {value, label}
    console.log( 'Selected Car:', selectedOption ); // Debugging pilihan pengguna
  };

  const handleYearChange = ( selectedOption ) =>
  {
    setSelectedYear( selectedOption );
    console.log( 'Selected Year:', selectedOption );
  };

  const handleModelChange = ( selectedOption ) =>
  {
    setSelectedModel( selectedOption );
    console.log( 'Selected Model:', selectedOption );
  };

  const handleProductChange = ( selectedOptions ) =>
  {
    setSelectedProduct( selectedOptions || [] ); // Update state with selected options
  };

  const handleOptionChange = ( option ) =>
  {
    setSelectedOption( option );
    setSelectedTab( "1" );  // Reset to the first tab when switching options
  };

  const handleSearch = async () =>
  {
    setIsSubmitted( true );  // Menandai bahwa tombol search telah diklik
    let hasError = false;

    if ( !selectedBrand )
    {
      setBrandError( true );
      hasError = true;
    } else
    {
      setBrandError( false );
    }

    if ( !selectedCar )
    {
      setCarError( true );
      hasError = true;
    } else
    {
      setCarError( false );
    }

    if ( !selectedModel )
    {
      setModelError( true );
      hasError = true;
    } else
    {
      setModelError( false );
    }

    if ( !selectedYear )
    {
      setYearError( true );
      hasError = true;
    } else
    {
      setYearError( false );
    }

    if ( !selectedProduct || selectedProduct.length === 0 )
    {
      setProductError( true );
      hasError = true;
    } else
    {
      setProductError( false );
    }

    if ( !hasError )
    {
      console.log( "All fields are valid, proceed with search..." );
      // Tambahkan logika pencarian disini
    }

    let searchParams = {};

    // Jika pencarian dilakukan dari `carBrand`, kirim parameter selain `part_number`
    if ( selectedOption === "carBrand" )
    {
      searchParams = {
        car_model: selectedCar?.value || null,
        model_type: selectedModel?.value || null,
        year: selectedYear?.value || null,
        id: Array.isArray( selectedProduct ) ? selectedProduct.map( product => product.value ) : [], // Safely handle selectedProduct
      };
    }

    console.log( searchParams );

    if ( selectedOption === "inCar" )
    {
      searchParams = {
        part_number: genuinePartNumber || null,
      };
    }

    try
    {
      // Mengirim request pencarian ke API dengan parameter yang dipilih
      const response = await axios.get( `${ API_BASE_URL }/product`, {
        params: searchParams, // Menggunakan params untuk query string

        headers: {
          Authorization: `Bearer ${ API_TOKEN }`,
        },
      } );
      // console.log( "API Response:", response.data ); // Log API response to check structure

      // Set products from response.data (response.data.data contains the array of products)
      if ( Array.isArray( response.data.data ) )
      {
        setProducts( response.data.data ); // Use response.data.data to get the array of products
      } else
      {
        console.error( "Unexpected data structure:", response.data );
        setProducts( [] ); // Fallback to an empty array if structure is unexpected
      }

      setLoading( false ); // Stop the loading state
    } catch ( error )
    {
      console.error( "Error fetching the products:", error );
      setLoading( false ); // Ensure to stop loading even if there's an error
    }
  };

  const [ currentPage, setCurrentPage ] = useState( 1 );
  const [ productsPerPage, setProductsPerPage ] = useState( 4 ); // Default to 4 per page
  const [ totalPages, setTotalPages ] = useState( Math.ceil( products.length / 4 ) );

  // Function to update products per page based on screen width
  const updateProductsPerPage = () =>
  {
    const width = window.innerWidth;
    let perPage;
    if ( width >= 992 )
    {
      perPage = 4; // 4 products for large screens (desktop)
    } else if ( width >= 768 )
    {
      perPage = 2; // 2 products for medium screens (tablet)
    } else
    {
      perPage = 1; // 1 product for small screens (mobile)
    }
    setProductsPerPage( perPage );
    setTotalPages( Math.ceil( products.length / perPage ) ); // Recalculate total pages
  };

  // Update productsPerPage when the component mounts and when the window is resized
  useEffect( () =>
  {
    updateProductsPerPage();
    window.addEventListener( "resize", updateProductsPerPage );
    return () =>
    {
      window.removeEventListener( "resize", updateProductsPerPage );
    };
  }, [ products.length ] ); // Also update if the product list changes

  const handlePageChange = ( pageNumber ) =>
  {
    if ( pageNumber >= 1 && pageNumber <= totalPages )
    {
      setCurrentPage( pageNumber );
    }
  };

  // Pagination Logic
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice( indexOfFirstProduct, indexOfLastProduct );

  if ( loading )
  {
    return <div>Loading...</div>; // Display loading spinner or message while fetching
  }

  return (

    <div id="navbar_top" >
      <Header></Header>

      { modeOn === 'banner' && (
        <div className="container-fluid px-0">
          <img src="/assets/banner_topt.png" className="w-100" alt="logo" />
        </div>
      )
      }

      {
        modeOn === 'slider' && (
          <Carousel>
            { [ "/assets/banner_1.png", "/assets/banner_2.png" ].map( ( banner, index ) => (
              <Carousel.Item key={ index }>
                <div className="container-fluid px-0">
                  <img src={ banner } className="w-100" alt={ `Slide ${ index + 1 }` } />
                </div>
              </Carousel.Item>
            ) ) }
          </Carousel>
        )
      }

      <div className="container-fluid">
        <div className="container mt-5">
          <div className="row">
            <div className="col-12">
              <h1 className="product_heading">Discover Our Product</h1>
            </div>
            <div className="col-12">
              <p className="product_sub_heading">
                Layanan Servis berkualitas prima,{ " " }
                <span className="inner-text">
                  agar performa Toyota Anda selalu optimal
                </span>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid px-0 overflow-x-hidden">
        <div className="row mt-5 d-flex justify-content-center">
          <div className="bg-white">

            <div className="container" style={ { position: 'relative' } }>
              <div className="scroll-buttons d-lg-none d-flex align-items-center">
                <img
                  onClick={ scrollRight }
                  className="ms-1 me-1 pe-2 mt-5 pt-2"
                  src="/assets/Frameright.svg"
                  alt="sad"
                  style={ { position: 'absolute', right: '10px', marginRight: '10px' } }
                />
                <img
                  className="ms-2 me-1 mt-4"
                  onClick={ scrollLeft }
                  src="/assets/Frameleft.svg"
                  alt=""
                  style={ { position: 'absolute', top: '0px' } }
                />
              </div>

              <div className="row">
                <div className="col-12">
                  <ul
                    className="nav nav-tabs custom-nav-tabs nav-fill custom-nav-fill ms-4 me-4 ms-lg-0 me-lg-0"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item custom-nav-item" role="presentation">
                      <button
                        className={ `nav-link custom-nav-item ${ activeTab === 'faq_tab_1' ? 'active' : '' }` }
                        id="faq_tab_1-tab"
                        onClick={ handleTabClickTab1 }
                        data-bs-toggle="tab"
                        data-bs-target="#faq_tab_1"
                        type="button"
                        role="tab"
                        aria-controls="faq_tab_1"
                        aria-selected={ activeTab === 'faq_tab_1' }
                      >
                        <div className="d-flex flex-column lh-lg">
                          <span>Service Parts</span>
                        </div>
                      </button>
                    </li>
                    <li className="nav-item custom-nav-item" role="presentation">
                      <button
                        className={ `nav-link custom-nav-item ${ activeTab === 'faq_tab_2' ? 'active' : '' }` }
                        id="faq_tab_2-tab"
                        onClick={ handleTabClickTab2 }
                        data-bs-toggle="tab"
                        data-bs-target="#faq_tab_2"
                        type="button"
                        role="tab"
                        aria-controls="faq_tab_2"
                        aria-selected={ activeTab === 'faq_tab_2' }
                      >
                        <div className="d-flex flex-column lh-lg">
                          <span>TMO</span>
                        </div>
                      </button>
                    </li>
                    <li className="nav-item custom-nav-item" role="presentation">
                      <button
                        className={ `nav-link custom-nav-item ${ activeTab === 'faq_tab_3' ? 'active' : '' }` }
                        id="faq_tab_3-tab"
                        onClick={ handleTabClickTab3 }
                        data-bs-toggle="tab"
                        data-bs-target="#faq_tab_3"
                        type="button"
                        role="tab"
                        aria-controls="faq_tab_3"
                        aria-selected={ activeTab === 'faq_tab_3' }
                      >
                        <div className="d-flex flex-column lh-lg">
                          <span>TGB</span>
                        </div>
                      </button>
                    </li>
                    <li className="nav-item custom-nav-item" role="presentation">
                      <button
                        className={ `nav-link custom-nav-item ${ activeTab === 'faq_tab_4' ? 'active' : '' }` }
                        id="faq_tab_4-tab"
                        onClick={ handleTabClickTab4 }
                        data-bs-toggle="tab"
                        data-bs-target="#faq_tab_4"
                        type="button"
                        role="tab"
                        aria-controls="faq_tab_4"
                        aria-selected={ activeTab === 'faq_tab_4' }
                      >
                        <div className="d-flex flex-column lh-lg">
                          <span>Tire Solution</span>
                        </div>
                      </button>
                    </li>
                    <li className="nav-item custom-nav-item" role="presentation">
                      <button
                        className={ `nav-link custom-nav-item ${ activeTab === 'faq_tab_5' ? 'active' : '' }` }
                        id="faq_tab_5-tab"
                        onClick={ handleTabClickTab5 }
                        data-bs-toggle="tab"
                        data-bs-target="#faq_tab_5"
                        type="button"
                        role="tab"
                        aria-controls="faq_tab_5"
                        aria-selected={ activeTab === 'faq_tab_5' }
                      >
                        <div className="d-flex flex-column lh-lg">
                          <span>T-OPT</span>
                        </div>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="container-fluid px-0">
              <div className="row">
                <div className="col-12">
                  <div className="tab-content" id="myTabContent">

                    <div
                      className={ `tab-pane fade ${ activeTab === 'faq_tab_1' ? 'show active' : '' }` }
                      id="faq_tab_1"
                      role="tabpanel"
                      aria-labelledby="faq_tab_1-tab"
                    >
                      <div className="container mt-5 mb-5">
                        <div className="row">
                          <div className="col-12">
                            <p className="product_title">
                              Toyota PRODUCT - service parts
                            </p>
                            <h1 className="product_heading">
                              Toyota Genuine Parts
                            </h1>
                            <p className="product_paragraph">
                              Toyota Genuine Parts yaitu suku cadang resmi dari
                              Toyota dengan mendegepankan kualitas dan telah
                              dirancang sesuai dengan spesifikasi dan kebutuhan
                              kendaraan kesayangan anda.
                            </p>
                            <img
                              src="/assets/Rectangle-118.png"
                              className="w-100"
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="container mt-5">
                        <div className="row">
                          <div className="col-12">
                            <p className="product_title">
                              Toyota PRODUCT - service parts
                            </p>
                            <h1 className="product_heading">
                              Market Valuable Parts (MVP)
                            </h1>
                            <p className="product_paragraph">
                              MVP adalah produk part Toyota yang khusus
                              dikembangkan untuk menawarkan harga yang lebih
                              kompetitif, dengan tetap memenuhi standard
                              kualitas Toyota.
                            </p>
                          </div>
                        </div>
                        <div className="container swiper_slider_1">
                          <Swiper
                            slidesPerView={ 3 }
                            breakpoints={ {
                              280: { slidesPerView: 1 },
                              768: { slidesPerView: 2 },
                              992: { slidesPerView: 3 },
                            } }
                            spaceBetween={ 30 }
                            navigation={ true }
                            modules={ [ Navigation ] }
                            className="mySwiper"
                          >
                            <SwiperSlide className="swiper-slide-fixed-height">
                              <div className="card2">
                                <div className="img">
                                  <Popup
                                    trigger={
                                      <img
                                        src="/assets/Image.png"
                                        alt="img"
                                        className="img-fluid custom_pointer"
                                        draggable="false"
                                      />
                                    }
                                    position="right center"
                                    modal
                                    closeOnDocumentClick
                                  >
                                    <iframe
                                      className="full-screen-60 rounded-4"
                                      src="https://www.youtube.com/embed/Yten5Nf0KW8"
                                      title="YouTube video player"
                                      frameBorder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                      referrerPolicy="strict-origin-when-cross-origin"
                                      allowFullScreen
                                    ></iframe>
                                  </Popup>
                                </div>
                                <div className="text-start card_customm_padding">
                                  <h4 className="card_heading">Oil Filter</h4>
                                  <p className="card_paragraph">
                                    Didesain khusus dengan harga yang kompetitif namun memenuhi standar Toyota. Tersedia 3 line up untuk model Old Kijang, Avanza, Rush, Agya, dan Calya.
                                  </p>
                                </div>
                                <div className="mt-3">
                                  <a onClick={ toggleVisibility } className={ `card_link ${ isVisible ? 'text-red' : '' }` }>
                                    Keunggulan
                                    <img
                                      className={ `ps-2 ${ isVisible ? 'arrow-down arrow-red' : '' }` }
                                      src="/assets/CROSS.svg"
                                      alt="arrow"
                                    />
                                  </a>
                                </div>
                              </div>
                              <div className={ `expandable-content ${ isVisible ? 'expanded' : '' }` }>
                                { isVisible && (
                                  <div className="card_background_image_1 mt-1 d-flex align-items-center">
                                    <img src='/assets/Vector.png' className="img-notepad_1" alt="notepad" />
                                    <p className="notePad_text me-3 mt-3 ps-3">Tahan terhadap tekanan tinggi</p>
                                  </div>
                                ) }
                              </div>
                            </SwiperSlide>

                            <SwiperSlide className="swiper-slide-fixed-height">
                              <div className="card2">
                                <div className="img">
                                  <img src="/assets/02-Air Filter.png" alt="img" className="img-fluid custom_pointer" draggable="false" />
                                </div>
                                <div className="text-start card_customm_padding">
                                  <h4 className="card_heading">Air Filter</h4>
                                  <p className="card_paragraph">
                                    Didesain khusus dengan harga yang kompetitif namun memenuhi standar Toyota. Tersedia 6 line up untuk model Old Kijang, Innova, Avanza, Agya dan Calya.
                                  </p>
                                </div>
                                <div className="mt-3">
                                  <a onClick={ toggleVisibility1 } className={ `card_link ${ isVisible1 ? 'text-red' : '' }` }>
                                    Keunggulan
                                    <img
                                      className={ `ps-2 ${ isVisible1 ? 'arrow-down arrow-red' : '' }` }
                                      src="/assets/CROSS.svg"
                                      alt="arrow"
                                    />
                                  </a>
                                </div>
                              </div>
                              <div className={ `expandable-content ${ isVisible1 ? 'expanded' : '' }` }>
                                { isVisible1 && (
                                  <div className="card_background_image_2 mt-1 d-flex align-items-center">
                                    <img src='/assets/Asset 2.svg' className="ms-3 margin_of_2" alt="asset" />
                                    <p className="notePad_text me-3 mt-3 ps-3">Performa filtrasi yang tinggi dibanding kompetitor</p>
                                  </div>
                                ) }
                              </div>
                            </SwiperSlide>

                            <SwiperSlide className="swiper-slide-fixed-height">
                              <div className="card2">
                                <div className="img">
                                  <img src="/assets/03-Brake Pad & Brake Shoe.png" alt="img" className="img-fluid custom_pointer" draggable="false" />
                                </div>
                                <div className="text-start card_customm_padding">
                                  <h4 className="card_heading">
                                    Brake Pad & Brake Shoe
                                  </h4>
                                  <p className="card_paragraph">
                                    Didesain khusus dengan harga yang kompetitif namun memenuhi standar Toyota. Tersedia 13 line up untuk model Old Kijang, Innova, Avanza, dan Calya.
                                  </p>
                                </div>
                                <div className="mt-3">
                                  <a onClick={ toggleVisibility2 } className={ `card_link ${ isVisible2 ? 'text-red' : '' }` }>
                                    Keunggulan
                                    <img
                                      className={ `ps-2 ${ isVisible2 ? 'arrow-down arrow-red' : '' }` }
                                      src="/assets/CROSS.svg"
                                      alt="arrow"
                                    />
                                  </a>
                                </div>
                              </div>
                              <div className={ `expandable-content ${ isVisible2 ? 'expanded' : '' }` }>
                                { isVisible2 && (
                                  <div className="card_background_image_3 d-flex flex-column">
                                    <div className="">
                                      <div className="d-flex align-items-left"><img src='/assets/Asset 2.svg' className="ms-3 third_double_image" alt="asset" />
                                        <p className="notePad_text text_334 me-3 mt-3 ps-3">Durabilitas lebih tinggi dibanding kompetitor, dapat dipakai hingga 45.000 KM.</p>
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-left custom_border_23 double_color_image" >
                                      <img src='/assets/Layer 2.svg' className="ms-3 image_334" alt="layer" />
                                      <p className="notePad_text me-3 mt-3 ps-3 text_336">Menggunakan material non asbestos yang ramah lingkungan.</p>
                                    </div>
                                  </div>
                                ) }
                              </div>
                            </SwiperSlide>

                            <SwiperSlide className="swiper-slide-fixed-height">
                              <div className="card2">
                                <div className="img">
                                  <img src="/assets/04-Disc Clutch.png" alt="img" className="img-fluid custom_pointer" draggable="false" />
                                </div>
                                <div className="text-start card_customm_padding">
                                  <h4 className="card_heading">Disc Clutch</h4>
                                  <p className="card_paragraph">
                                    Didesain khusus dengan harga yang kompetitif namun memenuhi standar Toyota. Tersedia 3 line up untuk model Avanza, Rush, Calya.
                                  </p>
                                </div>
                                <div className="mt-3">
                                  <a onClick={ toggleVisibility3 } className={ `card_link ${ isVisible3 ? 'text-red' : '' }` }>
                                    Keunggulan
                                    <img
                                      className={ `ps-2 ${ isVisible3 ? 'arrow-down arrow-red' : '' }` }
                                      src="/assets/CROSS.svg"
                                      alt="arrow"
                                    />
                                  </a>
                                </div>
                              </div>
                              <div className={ `expandable-content ${ isVisible3 ? 'expanded' : '' }` }>
                                { isVisible3 && (
                                  <div className="card_background_image_3">
                                    <div className="custom_grey_background d-flex flex-column">
                                      <div className="d-flex align-items-left"><img src='/assets/Asset 5.svg' className="ms-3 image_338" alt="asset" />
                                        <p className="notePad_text me-3 mt-3 ps-3 text_338">Fitur additional damper untuk mencegah noise.</p>
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-left custom_border_23 double_color_image_1">
                                      <img src='/assets/Layer 3.svg' className="ms-3" alt="layer" />
                                      <p className="notePad_text me-3 mt-3 ps-3 text-339">Memiliki durabilitas yang tinggi, tahan kondisi ekstrem.</p>
                                    </div>
                                  </div>
                                ) }
                              </div>
                            </SwiperSlide>

                            <SwiperSlide className="swiper-slide-fixed-height">
                              <div className="card2">
                                <div className="img">
                                  <img src="/assets/05-Rear Absorber.png" alt="img" className="img-fluid custom_pointer" draggable="false" />
                                </div>
                                <div className="text-start card_customm_padding">
                                  <h4 className="card_heading">Rear Absorber</h4>
                                  <p className="card_paragraph">
                                    Didesain khusus dengan harga yang kompetitif namun memenuhi standar Toyota. Tersedia 5 line up untuk model Old Kijang, Innova, Avanza, Agya dan Calya.
                                  </p>
                                </div>
                                <div className="mt-3">
                                  <a onClick={ toggleVisibility4 } className={ `card_link ${ isVisible4 ? 'text-red' : '' }` }>
                                    Keunggulan
                                    <img
                                      className={ `ps-2 ${ isVisible4 ? 'arrow-down arrow-red' : '' }` }
                                      src="/assets/CROSS.svg"
                                      alt="arrow"
                                    />
                                  </a>
                                </div>
                              </div>
                              <div className={ `expandable-content ${ isVisible4 ? 'expanded' : '' }` }>
                                { isVisible4 && (
                                  <div className="card_background_image_3">
                                    <div className="custom_grey_background d-flex flex-column">
                                      <div className="d-flex align-items-left"><img src='/assets/Layer 5.svg' className="ms-3 image_338 image_340" alt="layer" />
                                        <p className="notePad_text me-3 mt-3 ps-3 text_338 text_340">Damping force yang optimal sehingga stabil saat mengendara.</p>
                                      </div>
                                      <div className="d-flex align-items-left custom_border_23 double_color_image_1 double_color_image_2">
                                        <img src='/assets/Layer 3.svg' className="ms-3 image_341" alt="layer" />
                                        <p className="notePad_text me-3 mt-3 ps-3 text-339 text_341">Durabilitas yang tinggi, lebih awet dan tahan lama.</p>
                                      </div>
                                    </div>
                                  </div>
                                ) }
                              </div>
                            </SwiperSlide>
                          </Swiper>
                        </div>
                      </div>

                      <div className={ `logos d-flex justify-content-center mb-4 ${ isVisible || isVisible1 || isVisible2 || isVisible3 || isVisible4 ? 'move-down' : '' }` }>
                        <div className="mx-2">
                          <img
                            src="/assets/quality-service.png"
                            alt="t-care-img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="mx-2">
                          <img
                            src="/assets/genuine-parts (1).png"
                            alt="t-care-img"
                            className="img-fluid"
                          />
                        </div>
                      </div>

                    </div>

                    <div
                      className={ `tab-pane fade ${ activeTab === 'faq_tab_2' ? 'show active' : '' }` }
                      id="faq_tab_2"
                      role="tabpanel"
                      aria-labelledby="faq_tab_2-tab"
                    >
                      <div className="container ps-lg-5 pe-lg-5 ps-md-5 pe-md-5 ps-2 pe-2 mt-5">
                        <div className="row">
                          <div className="col-12">
                            <p className="product_title">Toyota PRODUCT - TMO</p>
                            <h1 className="product_heading">
                              Lubricant - TMO Engine Oil
                            </h1>
                            <p className="product_paragraph">
                              Minyak pelumas yang digunakan untuk melumasi dan
                              melindungi bagian-bagian mesin mobil.
                            </p>
                            <iframe className="w-100 rounded-4 custom-h-YT-v" src="https://www.youtube.com/embed/oOlvwZbXUiY?si=emBVIEEjm7orOTbC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                          </div>
                        </div>
                      </div>
                      <div className="container">
                        <div className="row new_slider d-flex justify-content-center">
                          <div className="col-lg-8 col-md-10 col-12">
                            <Swiper
                              slidesPerView={ 2 }
                              breakpoints={ {
                                280: {
                                  slidesPerView: 1,
                                },
                                768: {
                                  slidesPerView: 2,
                                },
                              } }
                              spaceBetween={ 30 }
                              freeMode={ true }
                              navigation={ true }
                              modules={ [ FreeMode, Navigation ] }
                              className="mySwiper"
                            >

                              <SwiperSlide>
                                <div className="card2">
                                  <div className={ `img-container ${ isVisible5 ? 'zoom' : '' }` }>
                                    <img
                                      src="/assets/Synthetic.png"
                                      alt="img"
                                      id="toggleVisibility5"
                                      className="img-fluid"
                                      draggable="false"
                                    />
                                  </div>
                                  <div className="text-container text-start ps-5 pe-5 ps-lg-0 pe-lg-0 ps-md-0 pe-md-0">
                                    <h4 className="card_heading">Synthetic</h4>
                                    <p className="card_2_text">
                                      Memiliki keunggulannya tidak mudah menguap
                                      dan kemampuan pelumasannya lebih merata.
                                    </p>
                                  </div>
                                  <div className="link-container">
                                    <a
                                      className={ `card_link ${ isVisible5 ? 'text-red' : '' }` }
                                      onClick={ toggleVisibility5 }
                                    >
                                      Cek Produk
                                      <img
                                        className={ `ps-2 ${ isVisible5 ? 'arrow-down arrow-red' : '' }` }
                                        src="/assets/CROSS.svg"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </SwiperSlide>

                              <SwiperSlide>
                                <div className="card2">
                                  <div className={ `img-container ${ isVisible6 ? 'zoom' : '' }` }>
                                    <img
                                      src="/assets/Full-Synthetic.png"
                                      className="img-fluid"
                                      id="toggleVisibility6"
                                      alt="img"
                                      draggable="false"
                                    />
                                  </div>
                                  <div className="text-container text-start ps-5 pe-5 ps-lg-0 pe-lg-0 ps-md-0 pe-md-0">
                                    <h4 className="card_heading">Full-Synthetic</h4>
                                    <p className="card_2_text">
                                      Memiliki kemampuan pelumasan paling baik,
                                      tahan terhadap suhu tinggi dan mampu
                                      meningkatkan efisiensi konsumsi bahan
                                      bakar lebih hemat bahan bakar.
                                    </p>
                                  </div>
                                  <div className="link-container">
                                    <a
                                      className={ `card_link ${ isVisible6 ? 'text-red' : '' }` }
                                      onClick={ toggleVisibility6 }
                                    >
                                      Cek Produk
                                      <img
                                        className={ `ps-2 ${ isVisible6 ? 'arrow-down arrow-red' : '' }` }
                                        src="/assets/CROSS.svg"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </SwiperSlide>


                            </Swiper>
                            { isVisible5 && (
                              <div className="row mt-1">
                                <div className="col-lg-4 col-6">
                                  <div className="show_cards_1">
                                    <div className="text-center">
                                      <h4 className="card_heading_233">10W-40<br></br>Gasoline</h4>
                                    </div>
                                    <div className="img">
                                      <img src="/assets/4L 5W-30 Gasoline_111.png" alt="img" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-6">
                                  <div className="show_cards_1">
                                    <div className="text-center">
                                      <h4 className="card_heading_233">10W-30<br></br>Gasoline</h4>
                                    </div>
                                    <div className="img">
                                      <img src="/assets/10W-30 Gasoline_Resize.png" alt="img" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-6 offset-lg-0 offset-3 mt-3 mt-lg-0">
                                  <div className="show_cards_2">
                                    <div className="text-center">
                                      <h4 className="card_heading_233">15W-40<br></br>Diesel</h4>
                                    </div>
                                    <div className="img">
                                      <img src="/assets/10W-30.png" alt="img" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) }
                          </div>
                          { isVisible6 && (
                            <div className="row mt-1">
                              <div className="col-lg-3 col-6">
                                <div className="show_cards_3">
                                  <div className="text-center">
                                    <h4 className="card_heading_233">5W-30 Gasoline</h4>
                                  </div>
                                  <div className="img">
                                    <img src="/assets/4L 5W-30 Gasoline12.png" alt="img" />
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-6">
                                <div className="show_cards_3">
                                  <div className="text-center">
                                    <h4 className="card_heading_233">5W-30 Diesel</h4>
                                  </div>
                                  <div className="img">
                                    <img src="/assets/5W-30 Diesel_resize.png" alt="img" />
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-6 mt-3 mt-lg-0">
                                <div className="show_cards_3">
                                  <div className="text-center">
                                    <h4 className="card_heading_233">0W-20 Gasoline</h4>
                                  </div>
                                  <div className="img">
                                    <img src="/assets/4L 5W-30 Gasoline13.png" alt="img" />
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-6 mt-3 mt-lg-0">
                                <div className="show_cards_3">
                                  <div className="text-center">
                                    <h4 className="card_heading_233">0W-20 Gasoline</h4>
                                  </div>
                                  <div className="img">
                                    <img src="/assets/4L 5W-30 Gasoline13.png" alt="img" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) }
                        </div>
                      </div>
                      <div className="container mt-5">
                        <div className="row">
                          <div className="col-12">
                            <h2 className="circle-Tabs-heading">Lakukan pengecekan keaslian oli dengan scan QR pada kemasan TMO:</h2>
                            <div class="circle-tabs mt-5">
                              <div className="d-flex flex-lg-column flex-md-column remove-svg w-100 align-items-center ms-5 ms-lg-0 ms-md-0">
                                <div>
                                  <a href="javascript:void(0)" className={ `class_1` }>
                                    1
                                  </a>
                                </div>
                                <div className="mt-md-3 mt-lg-3 text-lg-center text-md-center text-start me-5 me-lg-0 me-md-0">
                                  <p>Congkel segel tutup botol TMO.</p>
                                </div>
                              </div>
                              <div className="d-flex flex-lg-column flex-md-column w-100 mt-lg-0 mt-md-0 mt-3 align-items-center ms-5 ms-lg-0 ms-md-0">
                                <div>
                                  <a href="javascript:void(0)" className={ `class_1` }>
                                    2
                                  </a>
                                </div>
                                <div className="mt-md-3 mt-lg-3 text-lg-center text-md-center text-start me-5 me-lg-0 me-md-0">
                                  <p>Cabut penutup plastik tutup botol TMO.</p>
                                </div>
                              </div>
                              <div className="d-flex flex-lg-column flex-md-column w-100 mt-lg-0 mt-md-0 mt-3 align-items-center ms-5 ms-lg-0 ms-md-0">
                                <div >
                                  <a href="javascript:void(0)" className={ `class_1` }>
                                    3
                                  </a>
                                </div>
                                <div className="mt-md-3 mt-lg-3 text-lg-center text-md-center text-start me-5 me-lg-0 me-md-0">
                                  <p>Copot kepingan kertas pelindung seal aluminium TMO.</p>
                                </div>
                              </div>
                              <div className="d-flex flex-lg-column flex-md-column align-items-center w-100 remove-svg-2 mt-lg-0 mt-md-0 mt-3 ms-5 ms-lg-0 ms-md-0">
                                <div>
                                  <a href="javascript:void(0)" className={ `class_1` }>
                                    4
                                  </a>
                                </div>
                                <div className="mt-md-3 mt-lg-3 text-start text-lg-center text-md-center me-5 me-lg-0 me-md-0">
                                  <p>Cek keaslian dengan scan kode QR yang terdapat pada seal aluminium TMO.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="container mt-5">
                        <div className="row">
                          <div className="col-12">
                            <p className="product_title">Toyota PRODUCT -TMO</p>
                            <h1 className="product_heading">
                              Lubricant - TMO Non Engine Oil
                            </h1>
                            <p className="product_paragraph">
                              Minyak pelumas yang digunakan untuk melumasi dan
                              melindungi bagian-bagian selain mesin mobil.
                            </p>
                          </div>
                        </div>
                        <div className="container">
                          <div className="row custom_slide_design custom_slide_design_1 ms-4 me-4 ms-lg-0 me-lg-0 me-md-0 ms-md-0">
                            <Swiper
                              modules={ [ Virtual, Navigation, Pagination ] }
                              onSwiper={ setSwiperRef }
                              slidesPerView={ 3 }
                              breakpoints={ {
                                280: {
                                  slidesPerView: 1,
                                },
                                768: {
                                  slidesPerView: 2,
                                },
                                992: {
                                  slidesPerView: 2.5,
                                },
                                1400: {
                                  slidesPerView: 3,
                                },
                              } }
                              centeredSlides={ true }
                              spaceBetween={ 30 }
                              navigation={ true }
                              pagination={ { clickable: true } }
                              virtual
                            >
                              { slideContents.map( ( slideContents, index ) => (
                                <SwiperSlide key={ index } virtualIndex={ index }>
                                  <div className="card2">
                                    <div className="">
                                      <img
                                        className="card-img-top img-fluid"
                                        src={ slideContents.imageUrl }
                                        alt="Card image cap"
                                      />
                                      <div className="card-body custom-color-body">
                                        <div className="d-flex justify-content-center">
                                          <h3 className="card-title card_custom_font_size mt-3">
                                            { slideContents.title }
                                          </h3>
                                        </div>
                                        <p className="card-text_3 ps-2 pe-2 text-start pt-3">
                                          { slideContents.description }
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </SwiperSlide>
                              ) ) }
                            </Swiper>
                          </div>
                        </div>
                      </div>

                      {/* product slider */ }
                      <div className="container mt-5">
                        <div className="row">
                          <div className="col-12">
                            <p className="product_title">Toyota PRODUCT -TMO</p>
                            <h1 className="product_heading">Chemical Care</h1>
                            <p className="product_paragraph">
                              Hadirkan ketenangan dengan perbaikan Bodi Toyota di tangan yang paling berpengalaman.
                            </p>
                          </div>
                        </div>
                        <div className="row custom_slide_design ms-4 me-4 ms-lg-0 me-lg-0 me-md-0 ms-md-0">
                          <Swiper
                            modules={ [ Virtual, Navigation, Pagination ] }
                            onSwiper={ setSwiperRef }
                            slidesPerView={ 3 }
                            breakpoints={ {
                              280: {
                                slidesPerView: 1,
                              },
                              768: {
                                slidesPerView: 2,
                              },
                              992: {
                                slidesPerView: 2.5,
                              },
                              1400: {
                                slidesPerView: 3,
                              },
                            } }
                            centeredSlides={ true }
                            spaceBetween={ 30 }
                            navigation={ true }
                            pagination={ { clickable: true } }
                            virtual
                          >
                            { slideContent.map( ( slideContent, index ) => (
                              <SwiperSlide key={ index } virtualIndex={ index }>
                                <div className="card2">
                                  <div className="custom_box_shadow_1">
                                    { slideContent.src ? (
                                      <>
                                        <Popup
                                          trigger={
                                            <img
                                              src={ slideContent.imageUrl }
                                              alt={ slideContent.title }
                                              className="card-img-top img-fluid"
                                              draggable="false"
                                            />
                                          }
                                          position="right center"
                                          modal
                                          closeOnDocumentClick
                                        >
                                          <iframe
                                            className="w-100 rounded-4"
                                            height="500"
                                            src={ slideContent.src }
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            referrerPolicy="strict-origin-when-cross-origin"
                                            allowFullScreen
                                          ></iframe>
                                        </Popup>
                                      </>
                                    ) : (
                                      <img
                                        src={ slideContent.imageUrl }
                                        alt={ slideContent.title }
                                        className="card-img-top img-fluid"
                                        draggable="false"
                                      />
                                    ) }
                                    <div className="card-body custom-color-body text-start">
                                      <div className="d-flex justify-content-center">
                                        <h3 className="card-title ps-3 card_custom_font_size mt-3 text-center">
                                          { slideContent.title }
                                        </h3>
                                      </div>
                                      <p className="card-text_3 text-start ps-2 pe-2 pt-3">
                                        { slideContent.description }
                                      </p>
                                    </div>
                                  </div>

                                </div>
                              </SwiperSlide>
                            ) ) }
                          </Swiper>
                        </div>
                      </div>

                      <div className="container mt-5">
                        <div className="row">
                          <div className="col-12">
                            <p className="product_title">Toyota PRODUCT -TMO</p>
                            <h1 className="product_heading">TMO TGRI</h1>
                            <p className="product_paragraph">
                              Produk After Sales Toyota untuk seluruh varian mobil Toyota Anda, terutama Toyota GR.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="container mt-5">
                        <div className="row new_slider d-flex justify-content-center mt-5">
                          <div className="col-lg-8 col-md-10 col-12">
                            <Swiper
                              slidesPerView={ 2 }
                              breakpoints={ {
                                280: {
                                  slidesPerView: 1,
                                },
                                768: {
                                  slidesPerView: 2,
                                },
                              } }
                              spaceBetween={ 30 }
                              freeMode={ true }
                              navigation={ true }
                              modules={ [ FreeMode, Navigation ] }
                              className="mySwiper"
                            >
                              <SwiperSlide>
                                <div className="card">
                                  <div className="img">
                                    <img src="/assets/Image16.png" alt="img" className="img-fluid" draggable="false" />
                                  </div>
                                  <div className="text-start ps-5 pe-5 ps-lg-0 pe-lg-0 ps-md-0 pe-md-0 mt-3">
                                    <h4 className="card_heading">TMO TGRI Motor Oil 0W-20 API-SP</h4>
                                    <p className="card_2_text">
                                      Mendukung stabilitas performa mesin kendaraan dan memberikan performa maksimal untuk penggunaan racing dan harian kendaraan Toyota dengan bahan bakar bensin.
                                    </p>
                                  </div>
                                </div>
                              </SwiperSlide>

                              <SwiperSlide>
                                <div className="card">
                                  <div className="img">
                                    <img src="/assets/image15.png" className="img-fluid" alt="img" draggable="false" />
                                  </div>
                                  <div className="text-start ps-5 pe-5 ps-lg-0 pe-lg-0 ps-md-0 pe-md-0 mt-3">
                                    <h4 className="card_heading">TMO TGRI Injector Cleaner Gasoline</h4>
                                    <p className="card_2_text">
                                      Didukung dengan formula khusus untuk mengurangi serta membersihkan karbon dan endapan di dalam injektor dan ruang pembakaran.
                                    </p>
                                  </div>
                                </div>
                              </SwiperSlide>

                            </Swiper>
                          </div>
                        </div>
                      </div>

                      <div className="logos d-flex justify-content-center mt-5 mb-4">
                        <div className="mx-2">
                          <img
                            src="/assets/quality-service.png"
                            alt="t-care-img"
                            className="img-fluid"
                          />
                        </div>
                        {/* <div className="mx-2">
                          <img
                            src="/assets/genuine-parts (1).png"
                            alt="t-care-img"
                            className="img-fluid"
                          />
                        </div> */}
                        <div className="mx-2">
                          <img src="/assets/tmo.png" alt="t-care-img" className="img-fluid" />
                        </div>
                      </div>

                    </div>

                    <div
                      className={ `tab-pane fade ${ activeTab === 'faq_tab_3' ? 'show active' : '' }` }
                      id="faq_tab_3"
                      role="tabpanel"
                      aria-labelledby="faq_tab_3-tab"
                    >
                      <div className="container mt-5 mb-5">
                        <div className="row">
                          <div className="col-12">
                            <p className="product_title">
                              Toyota PRODUCT - TGB
                            </p>
                            <h1 className="product_heading">
                              TGB Maintenance Free
                            </h1>
                            <p className="product_paragraph">
                              Toyota Genuine Battery Maintenance Free merupakan tipe aki kering untuk mobil Toyota dengan standard kualitas Toyota.
                            </p>
                            <img
                              src="/assets/TGB-MF.png"
                              className="img-fluid"
                              alt="logo"
                            />
                          </div>
                        </div>
                        <div className="row pt-5">
                          <div className="col-12">
                            <h1 className="custom_color">Keunggulan:</h1>
                          </div>
                        </div>
                        <div className="row d-flex pt-3 text-start ms-lg-5 me-lg-5 custom_img_width">
                          <div className="col-sm-12 col-lg-6 col-md-6 d-flex align-items-center ps-5 pe-5 ps-lg-0 pe-lg-0 ps-md-0 pe-md-0">
                            <img src="/assets/svg1.svg" alt="svg1" />
                            <p className="custom-padding">
                              Didesain khusus untuk iklim tropis Indonesia
                            </p>
                          </div>
                          <div className="col-lg-6 pt-sm-3 pt-3 pt-md-0 pt-lg-0 col-md-6 col-sm-12 d-flex align-items-center ps-5 pe-5 ps-lg-2 pe-lg-0 ps-md-2 pe-md-0">
                            <img src="/assets/svg4.svg" alt="svg2" />
                            <p className="custom-padding">
                              Tidak perlu lagi mengecek dan menambah air aki
                            </p>
                          </div>
                        </div>
                        <div className="row d-flex pt-3 text-start ms-lg-5 me-lg-5 custom_img_width">
                          <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center ps-5 pe-5 ps-lg-0 pe-lg-0 ps-md-0 pe-md-0">
                            <img src="/assets/svg3.svg" alt="svg3" />
                            <p className="custom-padding">
                              Daya start (CCA) tinggi & dapat menyimpan energi
                              listrik lebih lama
                            </p>
                          </div>
                          <div className="col-lg-6 pt-sm-3 pt-3 pt-md-0 pt-lg-0 col-md-6 col-sm-12 d-flex align-items-center ps-5 pe-5 ps-lg-2 pe-lg-0 ps-md-2 pe-md-0">
                            <img src="/assets/svg2.svg" alt="svg4" />
                            <p className="custom-padding">Bebas korosi/Karat</p>
                          </div>
                        </div>
                        <div className="row d-flex pt-3 text-start ms-lg-5 me-lg-5 custom_img_width">
                          <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center ps-5 pe-5 ps-lg-0 pe-lg-0 ps-md-0 pe-md-0">
                            <img src="/assets/JaminanKualitas.svg" alt="svg3" />
                            <p className="custom-padding">
                              Warranty 1 tahun*
                            </p>
                          </div>
                        </div>
                        <div className="row text-start ms-lg-5 me-lg-5 ms-0 me-0 mt-3">
                          <div className="col-12">
                            <p className="Keunggulan_p">*Berlaku di seluruh dealer resmi Toyota & beberapa partshop partner Toyota tertentu.</p>
                          </div>
                        </div>
                        <div className="container mt-5">
                          <div className="row">
                            <div className="col-12">
                              <p className="product_title">
                                Toyota PRODUCT - TGB
                              </p>
                              <h1 className="product_heading">
                                TGB Standard Type
                              </h1>
                              <p className="product_paragraph">
                                Toyota Genuine Battery Standard Type merupakan
                                tipe aki basah untuk mobil Toyota dengan
                                standard kualitas Toyota
                              </p>
                              <img
                                src="/assets/TGB_MF_1.png"
                                className="img-fluid"
                                alt="logo"
                              />
                              <p className="product_paragraph mt-3">Warranty 1 tahun di seluruh dealer resmi Toyota.</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="logos d-flex justify-content-center mb-4">
                        <div className="mx-2">
                          <img
                            src="/assets/quality-service.png"
                            alt="t-care-img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="mx-2">
                          <img
                            src="/assets/genuine-parts (1).png"
                            alt="t-care-img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="mx-2">
                          <img src="/assets/logo-tmo (1) 1.png" alt="t-care-img" className="img-fluid" />
                        </div>
                      </div>

                    </div>

                    <div
                      className={ `tab-pane fade ${ activeTab === 'faq_tab_4' ? 'show active' : '' }` }
                      id="faq_tab_4"
                      role="tabpanel"
                      aria-labelledby="faq_tab_4-tab"
                    >
                      <div className="container mt-5">
                        <div className="row">
                          <div className="col-12">
                            <p className="product_title">Toyota PRODUCT</p>
                            <h1 className="product_heading">Tire Solution</h1>
                            <p className="product_paragraph">
                              Layanan penggantian ban di outlet resmi Toyota
                              dengan keunggulan{ " " }
                              <span className="custom_color_111">
                                produk ban bervariasi
                              </span>{ " " }
                              (tersedia berbagai macam merk & tipe) serta
                              memberikan{ " " }
                              <span className="custom_color_111">
                                kualitas ban terbaik
                              </span>{ " " }
                              (usia produksi ban terbaru)
                            </p>
                            <iframe className="w-100 rounded-4 custom-h-YT-v" src="https://www.youtube.com/embed/152dMG2nqtc?si=X6LL7pTsKLtX6ieQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                          </div>
                        </div>
                      </div>
                      <div className="container mt-5 custom-bg-color">
                        <div className="row mt-4">
                          <div className="col-12 mt-3 ms-md-3 me-md-3 ms-0 me-0">
                            <h1 className="ms-3 me-3 product_heading">
                              Perhatikan ciri-ciri Ban Mobil yang Dapat
                              Membahayakan di Perjalanan
                            </h1>
                          </div>
                        </div>
                        <div className="row ms-md-3 me-md-3 ms-0 me-0 ms-lg-5 me-lg-5 mt-4 mb-5">
                          <div className="col-lg-4 mb-0 col-md-6 col-sm-12 pt-0 pt-lg-0 mb-4">
                            <div className="custom_border">
                              <div className="d-flex custom_padding align-items-center text-start half_card">
                                <h3 className="custom_color_3 custom_style ps-3">
                                  1
                                </h3>
                                <p className="custom_color_3 custom_style_2  ps-3 pe-3 pt-2">
                                  Usia ban yang sudah lama / Jarak tempuh ban
                                  yang sudah tinggi
                                </p>
                              </div>
                              <div className="custom_color_card ps-3">
                                <p className="custom_font_p ps-3 pe-3 pt-2 text-start">
                                  Beresiko mengalami penurunan elastisitas /
                                  kelenturan karet hingga menjadi getas dan
                                  mengeras yang berpotensi pecah di kondisi
                                  tertentu
                                </p>
                                <img
                                  src="/assets/IG_TOYOTA_1.png"
                                  className="img-fluid"
                                />
                                <img
                                  src="/assets/IG_TOYOTA_2.png"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-12 mb-4">
                            <div className="custom_border">
                              <div className="d-flex custom_padding align-items-center text-start half_card">
                                <h3 className="custom_color_3 custom_style ps-3">
                                  2
                                </h3>
                                <p className="custom_color_3 custom_style_2  ps-3 pe-3 pt-2">
                                  Ban Tipis / Gundul
                                </p>
                              </div>
                              <div className="custom_color_card ps-3">
                                <p className="custom_font_p ps-3 pe-3 pt-2 text-start">
                                  Kedalaman kembangan ban 3mm beresiko mudah
                                  tertusuk benda tajam, daya cengkram berkurang
                                  saat pengereman, dan efek hydro / aquaplaning*
                                </p>
                                <img
                                  src="/assets/IG_TOYOTA_6.png"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-12 mb-4">
                            <div className="custom_border">
                              <div className="d-flex custom_padding align-items-center text-start half_card">
                                <h3 className="custom_color_3 custom_style ps-3">
                                  3
                                </h3>
                                <p className="custom_color_3 custom_style_2  ps-3 pe-3 pt-2">
                                  Ban Sobek / Retak / Benjol / Banyak Tambalan
                                </p>
                              </div>
                              <div className="custom_color_card ps-3">
                                <p className="custom_font_p ps-3 pe-3 pt-2 text-start">
                                  Kekuatan ban tidak optimal dan rentan pecah
                                  sewaktu-waktu di perjalanan (terutama saat
                                  melewati permukaan tidak rata)
                                </p>
                                <img
                                  src="/assets/IG_TOYOTA_5.png"
                                  className="img-fluid"
                                />
                                <img
                                  src="/assets/IG_TOYOTA_4.png"
                                  className="img-fluid"
                                />
                                <img
                                  src="/assets/IG_TOYOTA_3.png"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container">
                        <div className="row mt-5">
                          <div className="col-12 custom_hide_display">
                            <h1 className="product_heading">
                              Panjang Pengereman Terhadap Kedalaman Ban
                            </h1>
                            <img
                              src="/assets/Asset_6.png"
                              className="img-fluid mt-5 mb-5 image_1"
                            />
                            <img
                              src="/assets/Asset_6_mobile.png"
                              className="img-fluid mt-5 mb-5 image_2"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="container-fluid px-0 custom-bg-color">
                        <div className="container pt-5 pb-5">
                          <div className="row">
                            <div className="col-12">
                              <h1 className="product_heading">
                                Keunggulan Mengganti Ban di Toyota
                              </h1>
                            </div>
                          </div>
                          <div className="row justify-content-lg-between logoIcon mt-3">
                            <div className="col-lg-2 col-md-4 col-6 cards_custom_design">
                              <div className="card align-items-center cards_custom_design_1 pt-3">
                                <img
                                  src="/assets/card-icon-1.png"
                                  className="pt-2"
                                  alt="Logo"
                                />
                                <h5 className="card_heading_3 pt-2 mt-3">
                                  Produk Ban Bervariasi
                                </h5>
                                <p className="card_text_3 pb-4 mt-1">
                                  Tersedia berbagai macam merk & tipe
                                </p>
                              </div>
                            </div>
                            <div className=" col-lg-2 col-md-4 col-6 cards_custom_design">
                              <div className="card align-items-center cards_custom_design_1 pt-3">
                                <img
                                  src="/assets/card-icon-2.png"
                                  className="pt-2"
                                  alt="Logo"
                                />
                                <h5 className="card_heading_3 pt-2 mt-3">
                                  Kualitas Ban Terbaik
                                </h5>
                                <p className="card_text_3 pb-4 mt-1">
                                  Usia Produksi Ban Terbaru
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-6 pt-3 pt-lg-0 pt-md-0 cards_custom_design">
                              <div className="card align-items-center cards_custom_design_1 pt-3">
                                <img
                                  src="/assets/card-icon-3.png"
                                  className="pt-2"
                                  alt="Logo"
                                />
                                <h5 className="card_heading_3 pt-2 mt-3">
                                  One Stop Service
                                </h5>
                                <p className="card_text_3 pb-4 mt-1">
                                  Layanan pengecekan kendaraan & cuci mobil
                                  garansi produk ban*
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-6 pt-md-3 pt-lg-0 pt-3 cards_custom_design">
                              <div className="card  align-items-center cards_custom_design_1 pt-3">
                                <img
                                  src="/assets/card-icon-4.png"
                                  className="pt-2"
                                  alt="Logo"
                                />
                                <h5 className="card_heading_3 pt-2 mt-3">
                                  Bebas Biaya Tambahan
                                </h5>
                                <p className="card_text_3 pb-4 mt-1">
                                  Gratis biaya pemasangan, balancing & pentil
                                  ban
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-6 pt-md-3 pt-lg-0 pt-3 cards_custom_design">
                              <div className="card align-items-center cards_custom_design_1 pt-3">
                                <img
                                  src="/assets/card-icon-5.png"
                                  className="pt-2"
                                  alt="Logo"
                                />
                                <h5 className="card_heading_3 pt-2 mt-3">
                                  Ditangani oleh Teknisi Berpengalaman
                                </h5>
                                <p className="card_text_3 pb-4 mt-1">
                                  Tersedia berbagai macam merk & tipe
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container mt-5">
                        <div className="row">
                          <div className="col-12">
                            <h1 className="product_heading">
                              Solusi Terpercaya untuk Ban Anda
                            </h1>
                          </div>
                        </div>
                        <div className="row pt-3 justify-content-center last_images deskMt3">

                          <div className="col-4">
                            <img
                              src="/assets/Bridgestone_logo1.png"
                              className="img-fluid float-end clientImg mt-3"
                              alt="Logo"
                            />
                          </div>
                          <div className="col-4" >
                            <img
                              src="/assets/Bridgestone_logo 3.png"
                              className="img-fluid clientImg"
                              alt="Logo"
                            />
                          </div>
                          <div className="col-4">
                            <img
                              src="/assets/Bridgestone_logo3.png"
                              className="img-fluid float-start clientImg mt-3"
                              alt="Logo"
                            />
                          </div>

                          <div className="col-4">
                            <img
                              src="/assets/Bridgestone_logo4.png"
                              className="img-fluid float-end clientImg"
                              alt="Logo"
                            />
                          </div>
                          <div className="col-4">
                            <img
                              src="/assets/Bridgestone_logo5.png"
                              className="img-fluid clientImg"
                              alt="Logo"
                            />
                          </div>
                          <div className="col-4">
                            <img
                              src="/assets/Bridgestone_logo6.png"
                              className="img-fluid float-start clientImg"
                              alt="Logo"
                            />
                          </div>

                          <div className="col-4">
                            <img
                              src="/assets/Bridgestone_logo7.png"
                              className="img-fluid clientImg"
                              alt="Logo"
                            />
                          </div>

                        </div>

                        <div className="logos d-flex justify-content-center pt-5">
                          <div className="mx-2">
                            <img
                              src="/assets/quality-service.png"
                              alt="t-care-img"
                              className="img-fluid"
                            />
                          </div>
                          <div className="mx-2">
                            <img
                              src="/assets/genuine-parts (1).png"
                              alt="t-care-img"
                              className="img-fluid"
                            />
                          </div>
                          <div className="mx-2">
                            <img src="/assets/tmo.png" alt="t-care-img" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={ `tab-pane fade ${ activeTab === 'faq_tab_5' ? 'show active' : '' }` }
                      id="faq_tab_5"
                      role="tabpanel"
                      aria-labelledby="faq_tab_5-tab"
                    >
                      <div className="container mt-5">
                        <div className="row">
                          <div className="col-12">
                            <p className="product_title">Toyota PRODUCT</p>
                            <h1 className="product_heading">T-OPT</h1>
                            <p className="product_paragraph">
                              T-OPT hadir sebagai solusi perawatan kendaraan Toyota dengan menyediakan suku cadang fast moving yang termasuk paling sering diganti oleh pelanggan.
                              <br />
                              Dengan tagline “Parts dengan KUALITAS TERPERCAYA dan HARGA TERJANGKAU”, T-OPT dapat menjadi pilihan suku cadang yang terjangkau namun tetap dengan kualitas yang dijamin oleh Toyota.
                            </p>

                            {/* TODO: new adustment, creating tab for t-opt */ }
                            <div className="row justify-content-center my-5">
                              <div className="col-12 my-3">
                                <h2>
                                  Tambah Produk T-OPT Berdasarkan Model kendaraan Anda Atau Genuine Part Number
                                </h2>
                              </div>

                              {/* TODO:HOLD */ }
                              <div className="col-6 toggle-button-group mt-5">
                                <div className={ `toggle-button ${ selectedOption === "carBrand" ? "active" : "" }` } onClick={ () => handleOptionChange( "carBrand" ) }>
                                  <span class="label-tab">Car Brand Name</span>
                                </div>
                                <div className="divider">|</div>
                                <div className={ `toggle-button ${ selectedOption === "inCar" ? "active" : "" }` } onClick={ () => handleOptionChange( "inCar" ) }>
                                  <span class="label-tab">Genuine Part Number</span>
                                </div>
                              </div>
                            </div>

                            {/* TODO: HIDE */ }
                            { selectedOption === "carBrand" && (
                              <>
                                <div className="row my-5 justify-content-center align-items-center">
                                  <div className="col-md-4 col-lg-2 col-sm-12 label-title">
                                    Brand Name<span className="required">*</span>
                                    <Select
                                      options={ brandOptions }
                                      onChange={ handleBrandChange }
                                      value={ selectedBrand }
                                      placeholder="Brand Name"
                                      className={ `brand-select select-box product-select ${ isSubmitted && brandError ? 'error' : '' }` }
                                      classNamePrefix="select"
                                    />
                                    { isSubmitted && carError && (
                                      <div className="error-message">
                                        Brand Name is required.
                                      </div>
                                    ) }
                                  </div>

                                  <div className="col-md-4 col-lg-2 col-sm-12 label-title">
                                    Car Model<span className="required">*</span>
                                    <Select
                                      options={ carOptions }
                                      onChange={ handleCarChange }
                                      value={ selectedCar }
                                      placeholder="Select Car Model"
                                      className={ `car-select select-box product-select ${ isSubmitted && carError ? 'error' : '' }` }
                                      classNamePrefix="select"
                                    />
                                    { isSubmitted && carError && (
                                      <div className="error-message">
                                        Car Model is required.
                                      </div>
                                    ) }
                                  </div>

                                  <div className="col-md-4 col-lg-2 col-sm-12 label-title">
                                    Model Type<span className="required">*</span>
                                    <Select
                                      options={ modelOptions }
                                      onChange={ handleModelChange }
                                      value={ selectedModel }
                                      placeholder="Model Type"
                                      className={ `model-select select-box product-select ${ isSubmitted && modelError ? 'error' : '' }` }
                                      classNamePrefix="select"
                                    />
                                    { isSubmitted && carError && (
                                      <div className="error-message">
                                        Model Type is required.
                                      </div>
                                    ) }
                                  </div>

                                  <div className="col-md-4 col-lg-2 col-sm-12 label-title">
                                    Year Of Vehicle<span className="required">*</span>
                                    <Select
                                      options={ yearOptions }
                                      onChange={ handleYearChange }
                                      value={ selectedYear }
                                      placeholder="Select Year"
                                      className={ `year-select select-box product-select ${ isSubmitted && yearError ? 'error' : '' }` }
                                      classNamePrefix="select"
                                    />
                                    { isSubmitted && carError && (
                                      <div className="error-message">
                                        Year Of Vehicle is required.
                                      </div>
                                    ) }
                                  </div>

                                  <div className="col-md-4 col-lg-2 col-sm-12 label-title">
                                    Select Product<span className="required">*</span>
                                    <Select
                                      options={ productOptions || [] }
                                      onChange={ handleProductChange }
                                      value={ selectedProduct }
                                      placeholder="Product"
                                      className={ `custom-select product-select ${ isSubmitted && productError ? 'error' : '' }` }
                                      classNamePrefix="select"
                                      isMulti
                                      closeMenuOnSelect={ false }
                                      hideSelectedOptions={ false }
                                    />
                                    { isSubmitted && carError && (
                                      <div className="error-message">
                                        Product is required.
                                      </div>
                                    ) }
                                  </div>

                                  <div className={ `col-md-4 col-lg-2 col-sm-12  ${ isSubmitted ? 'btn-search-2' : 'btn-search' }` }>
                                    <button className="btn btn-danger btn-xl text-search w-100" onClick={ handleSearch }>
                                      Search
                                    </button>
                                  </div>
                                </div>
                              </>
                            ) }

                            { selectedOption === "inCar" && (
                              <>
                                <div className="row my-5 justify-content-center">
                                  <div className="col-md-4 text-left label-title">Genuine Part Number
                                    <input
                                      type="text"
                                      value={ genuinePartNumber }
                                      onChange={ ( e ) => setGenuinePartNumber( e.target.value ) } // Mengatur input part number
                                      placeholder="Type Genuine Part Number..."
                                      className="custom-input"
                                    />
                                  </div>
                                  <div className="col-md-1 label-search">
                                    <button className="btn btn-danger btn-xl text-search" onClick={ handleSearch }>Search</button>
                                  </div>
                                </div>
                              </>
                            ) }

                            {/* TODO: looping data product */ }
                            <div className="product-carousel-container mb-5">
                              <div className="row justify-content-center">
                                { currentProducts.map( ( product ) => (
                                  <div
                                    key={ product.id }
                                    className="col-12 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center product-card text-center"
                                  >
                                    <div className="card h-100">
                                      <img
                                        src={ product.image }
                                        alt={ product.name }
                                        className="card-img-slide product-image"
                                      />
                                      <div className="card-body">
                                        <h5 className="card-title">{ product.name }</h5>
                                        <h6 className="mt-3">Part Number</h6>
                                        <p className="card-text">{ product.part_number }</p>
                                      </div>
                                    </div>
                                  </div>
                                ) ) }
                              </div>

                              <div className="pagination d-flex justify-content-center">
                                <button
                                  className="pagination-btn"
                                  disabled={ currentPage === 1 }
                                  onClick={ () => handlePageChange( currentPage - 1 ) }
                                >
                                  &lt;
                                </button>
                                { [ ...Array( totalPages ) ].map( ( _, i ) => (
                                  <button
                                    key={ i }
                                    className={ `pagination-btn ${ currentPage === i + 1 ? "active" : "" }` }
                                    onClick={ () => handlePageChange( i + 1 ) }
                                  >
                                    { i + 1 }
                                  </button>
                                ) ) }
                                <button
                                  className="pagination-btn"
                                  disabled={ currentPage === totalPages }
                                  onClick={ () => handlePageChange( currentPage + 1 ) }
                                >
                                  &gt;
                                </button>
                              </div>
                            </div>

                            <iframe className="w-100 rounded-4 custom-h-YT-v" src="https://www.youtube.com/embed/rdxqeJHaJsE?si=RUwGjOGDy5Nc73Wc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                          </div>
                        </div>
                      </div>

                      {/* cabin air filter */ }
                      <div className="container">
                        <div className="row mt-5">
                          <div className="col-md-6">
                            <img src="/assets/opt1.png" className="img-fluid mt-5 mb-5 image_2" />
                          </div>
                          <div className="col-md-6 text-left pl3">
                            <div className="row mt-3">
                              <h1 className="left-header">Cabin Air Filter</h1>
                              <h5 className="mt-3">
                                Saran penggantian:
                                <span className="text-danger d-inline d-lg-none"><br />Setiap 20.000 km</span>
                                <span className="text-danger d-none d-lg-inline"> Setiap 20.000 km</span>
                              </h5>
                              <p>Terletak di sistem ventilasi untuk menyaring senyawa berbahaya seperti kotoran dan debu serta menjaga udara dalam kabin agar tetap bersih dan sehat.</p>
                            </div>
                            <div className="row mt-3">
                              <h5 className="header">Keunggulan:</h5>
                              <div>
                                <table>
                                  <tr>
                                    <td className="text-center"><img src="/assets/start.png" className="icons" /></td>
                                    <td className="naration">Menyaring kotoran, debu, dan asap dengan sangat baik</td>
                                  </tr>
                                  <tr><div className="mt-2"></div></tr>
                                  <tr>
                                    <td className="text-center"><img src="/assets/sound.png" className="icons" /></td>
                                    <td className="naration">Hembusan maksimal tanpa mengeluarkan suara</td>
                                  </tr>
                                  <tr><div className="mt-2"></div></tr>
                                  <tr>
                                    <td className="text-center"><img src="/assets/hammer.png" className="icons" /></td>
                                    <td className="naration">Mudah dipasang</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <div className="row mt-5">
                              <h5 className="left-header">Cocok untuk model:</h5>
                              <div className="d-flex flex-wrap">
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Agya</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Innova</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Hillux</p>
                                </div>
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Calya</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Fortuner</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Hillux Rangga</p>
                                </div>
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Avanza</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Yaris</p>
                                </div>
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Rush</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Vois</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* air filter */ }
                      <div className="container custom-bg-color">
                        <div className="row mt-5">
                          <div className="col-12 col-md-6 text-left pl3 order-2 order-md-1">
                            <div className="row mt-3">
                              <h1 className="left-header">Air Filter</h1>
                              <h5 className="mt-3">Saran penggantian:
                                <span className="text-danger d-inline d-lg-none"><br />Setiap 40.000 km</span>
                                <span className="text-danger d-none d-lg-inline"> Setiap 40.000 km</span>
                              </h5>
                              <p>Didesain khusus untuk memisahkan partikel berbahaya dari udara yang masuk, sehingga dapat mengurangi keausan mesin dan memperpanjang masa pakai mesin.</p>
                            </div>
                            <div className="row mt-3">
                              <h5 className="header">Keunggulan:</h5>
                              <div>
                                <table>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector1.png" className="icons" /></td>
                                    <td className="naration">Memiliki efisiensi filtrasi yang baik, dengan persentase 95%, sedangkan standard SNI 86%</td>
                                  </tr>
                                  <tr><div className="mt-2"></div></tr>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector2.png" className="icons" /></td>
                                    <td className="naration">Aliran udara yang terjaga</td>
                                  </tr>
                                  <tr><div className="mt-2"></div></tr>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector3.png" className="icons" /></td>
                                    <td className="naration">Ketahanan temperatur</td>
                                  </tr>
                                  <tr><div className="mt-2"></div></tr>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector4.png" className="icons" /></td>
                                    <td className="naration">Performa dan durabilitas yang terbukti baik</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <div className="row mt-5">
                              <h5 className="left-header">Cocok untuk model:</h5>
                              <div className="d-flex flex-wrap">
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Calya</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Vios</p>
                                  <p className="d-none d-lg-block"><img src="/assets/check_red.png" className="icons" />Hilux Rangga</p>
                                </div>
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Avanza</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Innova</p>
                                </div>
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Rush</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Fortuner</p>
                                </div>
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Yaris</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Hilux</p>
                                </div>
                                <div className="col-6 col-lg-3 d-lg-none">
                                  <p><img src="/assets/check_red.png" className="icons" />Hilux Rangga</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 order-1 order-md-2">
                            <img src="/assets/air_filter.png" className="img-fluid mt-5 mb-5 image_2" />
                          </div>
                        </div>
                      </div>

                      {/* oil filter */ }
                      <div className="container">
                        <div className="row mt-5">
                          <div className="col-md-6">
                            <img src="/assets/oil_filter_edited.png" className="img-fluid mt-5 mb-5 image_2" />
                          </div>
                          <div className="col-md-6 text-left pl3">
                            <div className="row mt-3">
                              <h1 className="left-header">Oil Filter</h1>
                              <h5 className="mt-3">Saran penggantian:
                                <span className="text-danger d-inline d-lg-none"><br />Setiap 20.000 km</span>
                                <span className="text-danger d-none d-lg-inline"> Setiap 20.000 km</span>
                              </h5>
                              <p>Berfungsi untuk menghilangkan kontaminan dalam oli mesin untuk menjaga performa, serta keawetan dan efisiensi mesin.</p>
                            </div>
                            <div className="row mt-3">
                              <h5 className="header">Keunggulan:</h5>
                              <div>
                                <table>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector5.png" className="icons" /></td>
                                    <td className="naration">Efisiensi filtrasi yang lebih baik</td>
                                  </tr>
                                  <tr><div className="mt-2"></div></tr>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector6.png" className="icons" /></td>
                                    <td className="naration">Anti-drainback yang lebih baik</td>
                                  </tr>
                                  <tr><div className="mt-2"></div></tr>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector7.png" className="icons" /></td>
                                    <td className="naration">Lebih kuat dan durabel</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <div className="row mt-5">
                              <h5 className="left-header">Cocok untuk model:</h5>
                              <div className="d-flex flex-wrap">
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Yaris</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Hillux</p>
                                </div>
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Vios</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Hillux Rangga</p>
                                </div>
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Innova</p>
                                </div>
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Fortuner</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* fuel filter */ }
                      <div className="container custom-bg-color">
                        <div className="row">
                          <div className="col-md-6 order-2 order-md-1 text-left pl3">
                            <div className="row mt-3">
                              <h1 className="left-header">Fuel Filter</h1>
                              <h5 className="mt-3">Saran penggantian:
                                <span className="text-danger d-inline d-lg-none"><br />Setiap 40.000 km</span>
                                <span className="text-danger d-none d-lg-inline"> Setiap 40.000 km</span>
                              </h5>
                              <p>Berfungsi untuk membersihkan kotoran dan partikel karat dari bahan bakar, menjaga agar tidak masuk kedalam mesin yang dapat menyebabkan penurunan performa mesin bahkan merusak mesin tersebut.</p>
                            </div>
                            <div className="row mt-3">
                              <h5 className="header">Keunggulan:</h5>
                              <div>
                                <table>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector8.png" className="icons" /></td>
                                    <td className="naration">Performa yang baik dan teruji</td>
                                  </tr>
                                  <tr><div className="mt-2"></div></tr>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector9.png" className="icons" /></td>
                                    <td className="naration">Efisiensi dan kapasitas filtrasi kotoran yang lebih baik</td>
                                  </tr>
                                  <tr><div className="mt-2"></div></tr>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector10.png" className="icons" /></td>
                                    <td className="naration">Anti-drainback yang lebih baik</td>
                                  </tr>
                                  <tr><div className="mt-2"></div></tr>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector11.png" className="icons" /></td>
                                    <td className="naration">Bypass valve system</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <div className="row mt-5">
                              <h5 className="left-header">Cocok untuk model:</h5>
                              <div className="d-flex flex-wrap">
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Innova</p>
                                </div>
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Fortuner</p>
                                </div>
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Hilux</p>
                                </div>
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Hilux Rangga</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 order-1 order-md-2 pl3">
                            <img src="/assets/fuel_filter.png" className="img-fluid mt-5 mb-5 image_2" />
                          </div>
                        </div>
                      </div>

                      {/* brake pad */ }
                      <div className="container">
                        <div className="row mt-5">
                          <div className="col-md-6">
                            <img src="/assets/brake_pad.png" className="img-fluid mt-5 mb-5 image_2" />
                          </div>
                          <div className="col-md-6 text-left pl3">
                            <div className="row mt-3">
                              <h1 className="left-header">Brake Pad</h1>
                              <div>
                                <h5 className="mt-3">Saran penggantian:
                                  <span className="text-danger d-inline d-lg-none"><br />40.000 km - 60.000 km</span>
                                  <span className="text-danger d-none d-lg-inline"> 40.000 km - 60.000 km</span>
                                </h5>
                              </div>
                              <p>Brake pad atau kampas rem akan menekan cakram yang berputar saat rem diinjak sehingga menimbulkan gesekan yang memperlambat laju kendaraan.</p>
                            </div>
                            <div className="row mt-3">
                              <h5 className="header">Keunggulan:</h5>
                              <div>
                                <table>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector12.png" className="icons" /></td>
                                    <td className="naration">Mengurangi noise dan getaran</td>
                                  </tr>
                                  <tr><div className="mt-2"></div></tr>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector13.png" className="icons" /></td>
                                    <td className="naration">Performa pengereman yang seimbang</td>
                                  </tr>
                                  <tr><div className="mt-2"></div></tr>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector14.png" className="icons" /></td>
                                    <td className="naration">Keausan kampas yang minimal</td>
                                  </tr>
                                  <tr><div className="mt-2"></div></tr>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector15.png" className="icons" /></td>
                                    <td className="naration">Ketebalan lapisan kampas</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <div className="row mt-5">
                              <h5 className="left-header">Cocok untuk model:</h5>
                              <div className="d-flex flex-wrap">
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Agya</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Fortuner</p>
                                </div>
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Avanza</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Innova</p>
                                </div>
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Rush</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Yaris</p>
                                </div>
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Calya</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Vios</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* brake Shoe */ }
                      <div className="container custom-bg-color">
                        <div className="row mt-5">
                          <div className="col-md-6 order-2 order-md-1 text-left pl3">
                            <div className="row mt-3">
                              <h1 className="left-header">Brake Shoe</h1>
                              <div>
                                <h5 className="mt-3">Saran penggantian:
                                  <span className="text-danger d-inline d-lg-none"><br />40.000 km - 60.000 km</span>
                                  <span className="text-danger d-none d-lg-inline"> 40.000 km - 60.000 km</span>
                                </h5>
                              </div>
                              <p>Dibuat dengan bahan terbaik yang memberikan daya pengeraman lebih tinggi dibanding yang lain.</p>
                            </div>
                            <div className="row mt-3">
                              <h5 className="header">Keunggulan:</h5>
                              <div>
                                <table>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector16.png" className="icons" /></td>
                                    <td className="naration">Efektivitas pengereman yang tinggi</td>
                                  </tr>
                                  <tr><div className="mt-2"></div></tr>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector17.png" className="icons" /></td>
                                    <td className="naration">Mengurangi noise</td>
                                  </tr>
                                  <tr><div className="mt-2"></div></tr>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector18.png" className="icons" /></td>
                                    <td className="naration">Tahan lama</td>
                                  </tr>
                                  <tr><div className="mt-2"></div></tr>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector19.png" className="icons" /></td>
                                    <td className="naration">Kekuatan rem yang tinggi</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <div className="row mt-5">
                              <h5 className="left-header">Cocok untuk model:</h5>
                              <div className="d-flex flex-wrap">
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Avanza</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Rush</p>
                                </div>
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Innova</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Yaris</p>
                                </div>
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Vios</p>
                                </div>
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Hilux</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 order-1 order-md-2 pl3">
                            <img src="/assets/brake_shoe.png" className="img-fluid mt-5 mb-5 image_2" />
                          </div>
                        </div>
                      </div>

                      {/* wiper blade */ }
                      <div className="container">
                        <div className="row mt-5">
                          <div className="col-md-6">
                            <img src="/assets/wiper_blade.png" className="img-fluid mt-5 mb-5 image_2" />
                          </div>
                          <div className="col-md-6 text-left pl3">
                            <div className="row mt-3">
                              <h1 className="left-header">Wiper Blade</h1>
                              <h5 className="mt-3">
                                Saran penggantian:
                                <span className="text-danger d-inline d-lg-none"><br />1 - 2 tahun</span>
                                <span className="text-danger d-none d-lg-inline"> 1 - 2 tahun</span>
                              </h5>
                              <p>Durabel dan cocok untuk setiap kendaraan (well-fittted) untuk memberikan visibilitas yang jernih. Menghasilkan “lapisan tipis” pada kaca windshield dengan meratakan permukaan air untuk mencegah pembiasan.</p>
                            </div>
                            <div className="row mt-3">
                              <h5 className="header">Keunggulan:</h5>
                              <div>
                                <table>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector20.png" className="icons" /></td>
                                    <td className="naration">Graphite coating yang sangat berkualitas</td>
                                  </tr>
                                  <tr><div className="mt-2"></div></tr>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector21.png" className="icons" /></td>
                                    <td className="naration">Tahan lama bahkan pada kondisi cuaca ekstrem</td>
                                  </tr>
                                  <tr><div className="mt-2"></div></tr>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector22.png" className="icons" /></td>
                                    <td className="naration">Tidak bergetar saat digunakan</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <div className="row mt-5">
                              <h5 className="left-header">Cocok untuk model:</h5>
                              <div className="d-flex flex-wrap">
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Agya</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Innova</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Hillux</p>
                                </div>
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Calya</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Fortuner</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Hillux Rangga</p>
                                </div>
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Avanza</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Yaris</p>
                                </div>
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Rush</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Vois</p>
                                </div>
                              </div>
                            </div>


                          </div>
                        </div>
                      </div>

                      {/* v belt */ }
                      <div className="container custom-bg-color">
                        <div className="row mt-5">
                          <div className="col-md-6 order-2 order-md-1 text-left pl3">
                            <div className="row mt-3">
                              <h1 className="left-header">V-Belt</h1>
                              <div>
                                <h5 className="mt-3">Saran penggantian:
                                  <span className="text-danger d-inline d-lg-none"><br />Setiap 80.000 km</span>
                                  <span className="text-danger d-none d-lg-inline"> Setiap 80.000 km</span>
                                </h5>
                              </div>
                              <p>Dibuat dengan bahan terbaik yang memberikan daya pengeraman lebih tinggi dibanding yang lain.</p>
                            </div>
                            <div className="row mt-3">
                              <h5 className="header">Keunggulan:</h5>
                              <div>
                                <table>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector23.png" className="icons" /></td>
                                    <td className="naration">Desain dengan material kanvas terbaik</td>
                                  </tr>
                                  <tr><div className="mt-2"></div></tr>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector24.png" className="icons" /></td>
                                    <td className="naration">Ramah lingkungan</td>
                                  </tr>
                                  <tr><div className="mt-2"></div></tr>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector25.png" className="icons" /></td>
                                    <td className="naration">Penggunaan bahan bakar sangat efisien</td>
                                  </tr>
                                  <tr><div className="mt-2"></div></tr>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector26.png" className="icons" /></td>
                                    <td className="naration">Ringan</td>
                                  </tr>
                                  <tr><div className="mt-2"></div></tr>
                                  <tr>
                                    <td className="text-center"><img src="/assets/Vector27.png" className="icons" /></td>
                                    <td className="naration">Minim getaran yang menyebabkan kegagalan transmisi power</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <div className="row mt-5">
                              <h5 className="left-header">Cocok untuk model:</h5>
                              <div className="d-flex flex-wrap">
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Calya</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Rush</p>
                                  <p className="d-none d-lg-block"><img src="/assets/check_red.png" className="icons" />Hilux Rangga</p>
                                </div>
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Avanza</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Yaris</p>
                                </div>
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Innova</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Vios</p>
                                </div>
                                <div className="col-6 col-lg-3">
                                  <p><img src="/assets/check_red.png" className="icons" />Fortuner</p>
                                  <p><img src="/assets/check_red.png" className="icons" />Hilux</p>
                                </div>
                                <div className="col-6 col-lg-3 d-lg-none">
                                  <p><img src="/assets/check_red.png" className="icons" />Hilux Rangga</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 order-1 order-md-2 pl3">
                            <img src="/assets/v_belt.png" className="img-fluid mt-5 mb-5 image_2" />
                          </div>
                        </div>
                      </div>

                      <div className="container mt100">
                        <div className="row">
                          <div className="col-md-12">
                            <h3>Temukan di</h3>
                            <h1 className="product_heading">
                              TOYOTA PART SHOP PARTNER & <a onClick={ goToWeb } style={ { cursor: 'pointer' } }><img src="/assets/otoexpert.png" alt="t-care-img" className="img-logo mb18" /></a>
                            </h1>
                          </div>
                        </div>
                        <div className="logos d-flex justify-content-center pt-5 mt-5">
                          <div className="mx-2">
                            <img src="/assets/logo_foot.png" alt="t-care-img" className="img-logo" />
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div >
  );
}

export default Products;
